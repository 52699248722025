import axios from "axios";
import {Link} from "react-router-dom";
import React from "react";
import {toast} from "react-toastify";

let Helpers = {
  axios: axios,
  baseUrl: '',
  COD: 6,
  setLocalStorageJsonData: (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  },
  getLocalStorageJsonData: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  setLocalStorageData: (key, data) => {
    localStorage.setItem(key, data);
  },
  getLocalStorageData: (key) => {
    return localStorage.getItem(key);
  },
  removeLocalStorageData: (key) => {
    return localStorage.removeItem(key);
  },
  awsStickerUrl: (slug, ver, file) => {
    return `https://d2k16ouylthisj.cloudfront.net/${slug}/${ver}/assets_client/stickers/${file}.png`
},
  imageResize:(deg, imageWidth, imageHeight, x, y, cropWidth, cropHeight)=>{
    let cWidth=cropWidth;
    let cHeight=cropHeight;
    if (deg === 90) {
      let ox = x
      let oy = y
      x = imageHeight - (cropHeight + oy);
      y = ox
      cropWidth = cHeight;
      cropHeight = cWidth;
    }
    else if (deg === 180) {
      let ox = x
      let oy = y
      x = imageWidth - (cropWidth + ox);
      y = (imageHeight - (cropHeight + oy));
    }
    else if (deg === 270) {
      let ox = x
      let oy = y
      x = oy;
      y = imageWidth - (cropWidth + ox);
      cropWidth = cHeight;
      cropHeight = cWidth;
    }
    return({x, y, cropWidth, cropHeight})
  },
  BREADCRUMB: function (breadcrumbs) {
    let string = [];
    string.push(breadcrumbs.map(function (data, key) {
      return (data.active) ? <li key={key} className="breadcrumb-item"><Link to={data.path}>{data.name}</Link></li> :
        <li key={key} className="breadcrumb-item active">{data.name}</li>;
    }));
    return <ol className="breadcrumb breadcrumb-new"> {string}</ol>;
  },
  /**
   * @description For toast from whole app
   * msg = message
   * duration = time of present in millisecond
   * type = 1 = success, 2 = warn, 3 = error
   * */
  toaster: (msg, duration, type) => {
    if (type === 1 || type === undefined) {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: duration
      });
    }else if (type === 2) {
      toast.warn(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: duration
      });
    }else if (type === 3) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: duration
      });
    }
  }
};

export default Helpers;
