import Aws from "../AwsService/Aws";
let fontFamily = function(id, version){
  Aws("live-product/"+id+"/"+version+"/fonts", (font)=>{
    font && font.Contents.forEach((arr, i)=>{
      let fontName = arr.Key.replace(font.Prefix+"/", "").replace(".ttf", "")
      let fontFace = new FontFace(fontName, 'url(https://zoomin-new.s3.ap-south-1.amazonaws.com/'+arr.Key+')');
      document.fonts.add(fontFace);
    })

  })
}
export default fontFamily;
