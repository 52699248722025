// if (process.env.REACT_APP_ENV === 'production') {
    //    PDF_API_URL: 'http://192.168.1.21/zoomin-pdf/',
    module.exports = {
        API_URL: 'https://zoominqa.com:8080/api/v1/',
        ADMIN_API_URL: 'https://e1cf-103-145-18-152.ngrok-free.app/admin/',
        PDF_API_URL: 'https://zoominqa.com/FPDI/',
        API_ROOT: 'https://zoominqa.com:8080/',
        
    //   API_URL: 'http://13.234.92.18:8080/api/v1/',
    //   PDF_API_URL: 'http://13.234.92.18/zoomin-pdf/',
    //   ADMIN_API_URL: 'http://13.234.92.18:8080/admin/',
    //   API_ROOT: 'http://13.234.92.18:8080/',

    //   API_URL: 'https://949e-2409-4040-e88-a1cf-b540-7982-b1a7-14cf.ngrok.io/api/v1/',
    //   PDF_API_URL: 'https://949e-2409-4040-e88-a1cf-b540-7982-b1a7-14cf.ngrok.io/zoomin-pdf/',
    //   ADMIN_API_URL: 'https://949e-2409-4040-e88-a1cf-b540-7982-b1a7-14cf.ngrok.io/admin/',
    //   API_ROOT: 'https://949e-2409-4040-e88-a1cf-b540-7982-b1a7-14cf.ngrok.io/',
   
    
        API_HEADER: {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        },
        ADMIN_API_HEADER: {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('adminToken'),
               
            }
        }
    };
// } 
// else {
    
//     module.exports = {
//       API_URL: 'http://13.234.92.18:8080/api/v1/',
//       PDF_API_URL: 'http://13.234.92.18/zoomin-pdf/',
//       ADMIN_API_URL: 'http://13.234.92.18:8080/admin/',
//       API_ROOT: 'http://13.234.92.18:8080/',
//         API_HEADER: {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Accept": "application/json",
//             }
//         },
//         ADMIN_API_HEADER: {
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + localStorage.getItem('adminToken')
//             }
//         }
//     };
// }