// const BASE_URL = 'http://192.168.1.23:1337/v1/'
import axios from "axios";
import MobileLanding from "../views/MobileLanding/List/List";
import Helpers from "./Helpers";
import { ADMIN_API_URL } from '../config';
let BASE_URL = ADMIN_API_URL, SHIPPING_URL;

// if (process.env.REACT_APP_ENV === 'production') {
//   BASE_URL = 'http://13.234.92.18:8080/admin/';
// } else {
//   BASE_URL = 'http://13.234.92.18:8080/admin/';
// }

// if (process.env.REACT_APP_ENV === 'production') {
// BASE_URL = 'https://zoominqa.com:8080/admin/';
// SHIPPING_URL = 'http://13.126.89.41/api/v1/script/'

// } else {
// BASE_URL = 'https://zoominqa.com:8080/admin/';
// SHIPPING_URL = 'http://13.126.89.41/api/v1/script/'
// }

// if (process.env.REACT_APP_ENV === 'production') {
BASE_URL = 'https://zoominqa.com:8080/admin/';
SHIPPING_URL = 'http://13.126.89.41/api/v1/script/'
// } else {
// BASE_URL = 'https://staging-api.zoominqa.com/admin/';
// SHIPPING_URL = 'http://13.126.89.41/api/v1/script/'
// }
// console.log(process.env.REACT_APP_ENV)

const handleError = (error) => {
  if (axios.isCancel(error)) {
    console.log('post Request canceled');
  } else {
    console.log(error)
    // Helpers.removeLocalStorageData('adminToken');
    // Helpers.removeLocalStorageData('theme');
    // window.location.reload();
  }
};

const callApi = (tag, config, body = "") => axios.post(BASE_URL + tag, body, config).then(data => {
  if (data.status === 403) {
    Helpers.removeLocalStorageData('adminToken');
    Helpers.removeLocalStorageData('theme');
    this.props.history.push('/')
  } else {
    return data
  }
}).catch(handleError);

const callApiGet = (tag, config, body = "") =>
  //console.log(config);
  axios.get(BASE_URL + tag, { headers: config.headers }).then(data => {
    if (data.data.status === 403) {
      Helpers.removeLocalStorageData('adminToken');
      Helpers.removeLocalStorageData('theme');
      this.props.history.push('/')
    } else {
      return data
    }
  }).catch(handleError);

const callApiDelete = (tag, config, body = "") =>
  axios.delete(BASE_URL + tag, { headers: config.headers }).then(data => {
    if (data.status === 403) {
      Helpers.removeLocalStorageData('adminToken');
      Helpers.removeLocalStorageData('theme');
      this.props.history.push('/')
    } else {
      return data
    }
  }).catch(handleError);

const callApiGetShipping = (tag, config, body = "") =>
  //console.log(config);
  axios.get(SHIPPING_URL + tag, { headers: config.headers }).then(data => {
    if (data.data.status === 403) {
      Helpers.removeLocalStorageData('adminToken');
      Helpers.removeLocalStorageData('theme');
      this.props.history.push('/')
    } else {
      return data
    }
  }).catch(handleError);


const Api = {
  dashboard: (reqConfig) => callApi('dashboard', reqConfig),                                                 //done
  changePassword: (body, reqConfig) => callApi('change-password', reqConfig, body),                          //done
  countryList: (reqConfig) => callApi('country-list', reqConfig),                                            //done
  partner: (reqConfig) => callApi('partner', reqConfig),                                                     //done
  campaignNameList: (reqConfig) => callApi('campaign-list-names', reqConfig),

  //admin
  login: (body, reqConfig) => callApi('login', reqConfig, body),                                             //done
  twoFacodeVerification: (body, reqConfig) => callApi('2facode-verification', reqConfig, body),              //done
  forgotPassword: (body, reqConfig) => callApi('forgot-password', reqConfig, body),                          //done
  adminDelete: (body, reqConfig) => callApi('admin-delete', reqConfig, body),                                //done
  adminCreate: (body, reqConfig) => callApi('admin-create', reqConfig, body),                                //done
  adminDetail: (body, reqConfig) => callApi('admin-detail', reqConfig, body),                                //done
  adminRoleType: (reqConfig) => callApi('admin-role-type', reqConfig),                                       //done
  adminUserList: (body, reqConfig) => callApi('admin-user-list', reqConfig, body),                           //done

  //partner
  partnerDetail: (body, reqConfig) => callApi('partner-detail', reqConfig, body),                            //done
  partnerCreate: (body, reqConfig) => callApi('partner-create', reqConfig, body),                            //done
  partnerDelete: (body, reqConfig) => callApi('partner-delete', reqConfig, body),                            //done
  partnerList: (body, reqConfig) => callApi('partner-list', reqConfig, body),                                //done
  partnerListCsv: (body, reqConfig) => callApi('partner-list-csv', reqConfig, body),                         //done

  //campaign
  campaignDetail: (body, reqConfig) => callApi('campaign-detail', reqConfig, body),                          //done
  campaignCreate: (body, reqConfig) => callApi('campaign-create', reqConfig, body),                          //done
  campaignDelete: (body, reqConfig) => callApi('campaign-delete', reqConfig, body),                          //done
  campaignList: (body, reqConfig) => callApi('campaign-list', reqConfig, body),                              //done
  campaignListCsv: (body, reqConfig) => callApi('campaign-list-csv', reqConfig, body),                       //done
  campaignReport: (body, reqConfig) => callApi('campaign-report', reqConfig, body),                          //done

  //promo and banner
  homepagePromoDetails: (body, reqConfig) => callApi('homepage-promo-details', reqConfig, body),             //done
  canvaPromoDetails: (body, reqConfig) => callApi('canva-promo-details', reqConfig, body),                   //done
  storyPromoDetails: (body, reqConfig) => callApi('story-promo-details', reqConfig, body),                   //done
  bannerList: (body, reqConfig) => callApi('banner-list', reqConfig, body),                                  //done
  bannerPositionChange: (body, reqConfig) => callApi('banner-position-change', reqConfig, body),             //done
  bannerDelete: (body, reqConfig) => callApi('banner-delete', reqConfig, body),                              //done
  getLaunchCoupons: (body, reqConfig) => callApi('get-launch-coupons', reqConfig, body),                     //done
  homepagePromoCreate: (body, reqConfig) => callApi('homepage-promo-create', reqConfig, body),               //done
  canvaPromoCreate: (body, reqConfig) => callApi('canva-promo-create', reqConfig, body),                     //done
  storyPromoCreate: (body, reqConfig) => callApi('story-promo-create', reqConfig, body),                     //done
  setLaunchCodeConfiguration: (body, reqConfig) => callApi('set-launchcode-configuration', reqConfig, body), //done
  bannerDetail: (body, reqConfig) => callApi('banner-detail', reqConfig, body),
  bannerCreate: (body, reqConfig) => callApi('banner-create', reqConfig, body),                          //done
  campaignBannerCreate: (body, reqConfig) => callApi('campaign-banner-create', reqConfig, body),
  walletbannerCreate: (body, reqConfig) => callApi('wallet-banner-create', reqConfig, body),
  walletbannerList: (body, reqConfig) => callApi('wallet-banner-list', reqConfig, body),
  walletbannerPositionChange: (body, reqConfig) => callApi('wallet-banner-position-change', reqConfig, body),
  walletbannerDetail: (body, reqConfig) => callApi('wallet-banner-detail', reqConfig, body),
  walletbannerDelete: (body, reqConfig) => callApi('wallet-banner-delete', reqConfig, body),
  campaignBanner: (body, reqConfig) => callApi('campaign-banner-list', reqConfig, body),
  //done

  //product
  categoryList: (reqConfig) => callApi('category-list', reqConfig),                                          //done
  productDetail: (body, reqConfig) => callApi('product-detail', reqConfig, body),                            //done
  productPriceDetail: (body, reqConfig) => callApi('product-price-detail', reqConfig, body),                 //done
  productPriceEdit: (body, reqConfig) => callApi('product-price-edit', reqConfig, body),                     //done
  productListCsv: (body, reqConfig) => callApi('product-list-csv', reqConfig, body),                         //done
  productPositionChange: (body, reqConfig) => callApi('product-position-change', reqConfig, body),           //not used
  productPriceList: (body, reqConfig) => callApi('product-price-list', reqConfig, body),                     //done
  addEditProductVersion: (body, reqConfig) => callApi('add-edit-product-version', reqConfig, body),
  productVersionList: (body, reqConfig) => callApi('product-version-list', reqConfig, body),
  platformProductVersionList: (body, reqConfig) => callApi('platform-product-version-list', reqConfig, body),
  addVersionProductList: (body, reqConfig) => callApi('add-version-product-list', reqConfig, body),
  addVersionProduct: (body, reqConfig) => callApi('add-version-products', reqConfig, body),
  updateProductWithTemplate: (body, reqConfig) => callApi('update-product-with-template', reqConfig, body),

  //coupon
  randomCode: (reqConfig) => callApi('random-code', reqConfig),
  category: (reqConfig) => callApi('category', reqConfig),
  couponCreate: (body, reqConfig) => callApi('coupon-create', reqConfig, body),                              //done
  couponDelete: (body, reqConfig) => callApi('coupon-delete', reqConfig, body),                              //done
  couponListCsv: (body, reqConfig) => callApi('coupon-list-csv', reqConfig, body),                           //done
  couponDetail: (body, reqConfig) => callApi('coupon-detail', reqConfig, body),
  bulkUpdatecouponDetail: (body, reqConfig) => callApi('bulk-coupon-detail', reqConfig, body),                              //done
  //done
  couponList: (body, reqConfig) => callApi('coupon-list', reqConfig, body),                                  //done
  product: (reqConfig) => callApi('product', reqConfig),                                                     //done
  campaign: (body, reqConfig) => callApi('campaign', reqConfig, body),                                       //done
  paymentMethodList: (reqConfig) => callApi('payment-method-list', reqConfig),                               //done
  couponOfferRefresh: (reqConfig) => callApi('coupon-offer-refresh', reqConfig),
  couponOfferInactive: (reqConfig) => callApi('coupon-offer-inactive-refresh', reqConfig),

  //bulk coupon
  bulkCouponCreate: (body, reqConfig) => callApi('bulk-coupon-generate', reqConfig, body),                   //done
  bulkCouponEdit: (body, reqConfig) => callApi('bulk-coupon-edit', reqConfig, body),                   //done
  bulkCouponList: (body, reqConfig) => callApi('bulk-coupon-list', reqConfig, body),                         //done
  bulkCouponDelete: (body, reqConfig) => callApi('bulk-coupon-delete', reqConfig, body),                     //done
  bulkCouponCSVDownload: (body, reqConfig) => callApi('bulk-coupon-csv', reqConfig, body),                   //done

  //courier
  courierDetail: (body, reqConfig) => callApi('courier-detail', reqConfig, body),                            //not used
  courierCreate: (body, reqConfig) => callApi('courier-create', reqConfig, body),                            //not used
  courierDelete: (body, reqConfig) => callApi('courier-delete', reqConfig, body),                            //not used
  courierListCsv: (body, reqConfig) => callApi('courier-list-csv', reqConfig, body),                         //done
  courierList: (body, reqConfig) => callApi('courier-list', reqConfig, body),                                //done

  //postal code
  postalCodeDetail: (body, reqConfig) => callApi('postal-code-detail', reqConfig, body),                     //not used
  postalCodeCreate: (body, reqConfig) => callApi('postal-code-create', reqConfig, body),                     //not used
  postalCodeList: (body, reqConfig) => callApi('postal-code-list', reqConfig, body),                         //done
  postalCodeImportCsv: (body, reqConfig) => callApi('postal-code-import-csv', reqConfig, body),              //to be checked
  importRewardUserCsv: (body, reqConfig) => callApi('import-user-reward', reqConfig, body),                  //done
  postalCodeListCsv: (body, reqConfig) => callApi('postal-code-list-csv', reqConfig, body),                  //done

  //Referral
  promoDelete: (body, reqConfig) => callApi('promo-delete', reqConfig, body),                                //not used
  promoList: (body, reqConfig) => callApi('promo-list', reqConfig, body),                                    //done
  promoListCsv: (body, reqConfig) => callApi('promo-list-csv', reqConfig, body),                             //done
  promoDetail: (body, reqConfig) => callApi('promo-detail', reqConfig, body),                                //not used
  promoCreate: (body, reqConfig) => callApi('promo-create', reqConfig, body),                                //not used
  couponProduct: (body, reqConfig) => callApi('coupon-product', reqConfig, body),                            //not used

  //report
  courierNameList: (reqConfig) => callApi('courier-name-list', reqConfig),                                   //done
  courierReports: (body, reqConfig) => callApi('courier-reports', reqConfig, body),                          //done
  subOrdersReport: (body, reqConfig) => callApi('suborders-report', reqConfig, body),                        //done
  processedSubOrderReport: (body, reqConfig) => callApi('suborders-to-be-processed', reqConfig, body),       //done
  subordersDateRange: (body, reqConfig) => callApi('suborders-date-range', reqConfig, body),                 //done
  pepoUserList: (body, reqConfig) => callApi('pepo-user-list', reqConfig, body),                             //done
  logisticsReports: (body, reqConfig) => callApi('logistics-reports', reqConfig, body),
  lifeTimeReports: (body, reqConfig) => callApi('life-time-report', reqConfig, body),
  SubOrderStatusReports: (body, reqConfig) => callApi('life-time-report-suborder', reqConfig, body),


  cronReports: (body, reqConfig) => callApi('crons-report', reqConfig, body),
  rewardReports: (body, reqConfig) => callApi('user-reward-reports', reqConfig, body),
  pincodeRevenueReport: (body, reqConfig) => callApi('pincode-revenue', reqConfig, body),
  cltvReports: (body, reqConfig) => callApi('cltv-reportwith-filter', reqConfig, body),
  suborderDownload: (body, reqConfig) => callApi('download-order-zip', reqConfig, body),
  themeReport: (body, reqConfig) => callApi('theme-report', reqConfig, body),
  getProductList: (body, reqConfig) => callApi('get-product-bycategory', reqConfig, body),
  registerUserReport: (body, reqConfig) => callApi('registered-user-report', reqConfig, body),

  //user
  userDetail: (body, reqConfig) => callApi('user-detail', reqConfig, body),                                  //done
  userEdit: (body, reqConfig) => callApi('user-edit', reqConfig, body),                                      //done
  changeUserPromocode: (body, reqConfig) => callApi('change-user-promocode', reqConfig, body),               //done
  userLoginHistory: (body, reqConfig) => callApi('user-login-history', reqConfig, body),                     //done
  orderDetailUser: (body, reqConfig) => callApi('order-detail-user', reqConfig, body),                       //done
  userRewardHistory: (body, reqConfig) => callApi('user-reward-history', reqConfig, body),                   //done
  userListCsv: (body, reqConfig) => callApi('user-list-csv', reqConfig, body),                               //done
  userList: (body, reqConfig) => callApi('user-list', reqConfig, body),                                      //done
  usersCount: (body, reqConfig) => callApi('user-count', reqConfig, body),                                   //done
  userAddReward: (body, reqConfig) => callApi('admin-add-reward', reqConfig, body),                          //done
  verifyEmail: (body, reqConfig) => callApi('admin-verify-email', reqConfig, body),
  verifyPhone: (body, reqConfig) => callApi('admin-verify-mobile', reqConfig, body),
  updateCodStatus: (body, reqConfig) => callApi('update-cod-status', reqConfig, body),                       //done
  ExportUserReportCsv: (body, reqConfig) => callApi('export-usercountrevnue-reports', reqConfig, body),
  userDelete: (body, reqConfig) => callApi('user-delete', reqConfig, body),
  fetchUserorderRevenue: (body, reqConfig) => callApi('get-user-order-revenue', reqConfig, body),                                   //done


  //Configuration
  setCodConfiguration: (body, reqConfig) => callApi('set-cod-configuration', reqConfig, body),               //done
  setShippingMethodDays: (body, reqConfig) => callApi('set-shipping-method-days', reqConfig, body),          //done
  configDetails: (body, reqConfig) => callApi('config-details', reqConfig, body),                            //done
  setPromoConfiguration: (body, reqConfig) => callApi('set-promo-configuration', reqConfig, body),           //done
  setRewardConfiguration: (body, reqConfig) => callApi('set-reward-configuration', reqConfig, body),         //done
  switchPaymentGateway: (body, reqConfig) => callApi('switch-payment-gateway', reqConfig, body),             //done
  bulkOrderEmailSet: (body, reqConfig) => callApi('bulk-order-email-set', reqConfig, body),                  //done
  setPriceConfiguration: (body, reqConfig) => callApi('set-price-configuration', reqConfig, body),           //done
  setForceUpdate: (body, reqConfig) => callApi('set-force-update', reqConfig, body),
  setMaintenanceMode: (body, reqConfig) => callApi('set-maintenance-mode', reqConfig, body),
  setShippingMethodStatus: (body, reqConfig) => callApi('set-shipping-method-status', reqConfig, body),

  //shipping
  recommendedCourier: (body, reqConfig) => callApi('recommended-courier', reqConfig, body),                  //done
  createLabel: (body, reqConfig) => callApi('create-label', reqConfig, body),                                //done
  allOrderNoteList: (body, reqConfig) => callApi('all-order-note-list', reqConfig, body),
  adminPrinter: (body, reqConfig) => callApi('admin-printer', reqConfig, body),
  allPrinterList: (body, reqConfig) => callApi('all-printers-list', reqConfig, body),
  updateAdminPrinter: (body, reqConfig) => callApi('update-admin-printer', reqConfig, body),
  recommendedCourierNew: (body, reqConfig) => callApi('recommended-courier-new', reqConfig, body),           //done
  manualShipping: (body, reqConfig) => callApi('manual-shipping', reqConfig, body),                          //done
  updateAwbNumber: (body, reqConfig) => callApi('update-manualtracking', reqConfig, body),
  getShippingStatus: (body, reqConfig, trackingId) => callApiGetShipping(`shipping-status-update/${trackingId}`, reqConfig, body),


  //order
  changeOrderAddress: (body, reqConfig) => callApi('change-order-address', reqConfig, body),                 //done
  orderNoteList: (body, reqConfig) => callApi('order-note-list', reqConfig, body),                           //done
  orderNoteCreate: (body, reqConfig) => callApi('order-note-create', reqConfig, body),                       //done
  orderNoteDelete: (body, reqConfig) => callApi('order-note-delete', reqConfig, body),                       //done
  orderListCsv: (body, reqConfig) => callApi('order-list-csv', reqConfig, body),                             //done
  orderList: (body, reqConfig) => callApi('order-list', reqConfig, body),                                    //done
  ordersCount: (body, reqConfig) => callApi('order-count', reqConfig, body),                                 //done
  orderDetail: (body, reqConfig) => callApi('order-detail', reqConfig, body),                                //done
  subOrderDetail: (body, reqConfig) => callApi('sub-order-detail', reqConfig, body),                         //done
  getSubOrderJson: (body, reqConfig) => callApi('get-sub-order-json', reqConfig, body),
  updateSubOrderJson: (body, reqConfig) => callApi('update-sub-order-json', reqConfig, body),
  generatePDF: (body, reqConfig) => callApi('generate-pdf', reqConfig, body),                                //done
  cancelOrder: (body, reqConfig) => callApi('cancel-order', reqConfig, body),                                //done
  cancelSubOrder: (body, reqConfig) => callApi('cancel-sub-order', reqConfig, body),                         //done
  changeOrderStatus: (body, reqConfig) => callApi('change-order-status', reqConfig, body),
  addPaymentDetail: (body, reqConfig) => callApi('add-payment-details', reqConfig, body),
  markAsPrepaid: (body, reqConfig) => callApi('mark-order-prepaid', reqConfig, body),
  markOrderConfirm: (body, reqConfig) => callApi('mark-order-confirm', reqConfig, body),
  changeSubOrderStatus: (body, reqConfig) => callApi('change-sub-order-status', reqConfig, body),
  donationList: (body, reqConfig) => callApi('order-donation-details', reqConfig, body),                     //done
  orderStatusHistory: (body, reqConfig) => callApi('order-status-history', reqConfig, body),
  addCouponDetail: (body, reqConfig) => callApi('order-apply-coupon', reqConfig, body),
  viewOrderJson: (body, reqConfig) => callApi('view-order-json', reqConfig, body),
  updateOrderJson: (body, reqConfig) => callApi('update-order-json', reqConfig, body),                                                                         //done
  downloadOrderJson: (body, reqConfig) => callApi('auto-pdf-processing', reqConfig, body),                                                                         //done
  autoPdfProcessing: (body, reqConfig) => callApi('pdf-processing-photobook', reqConfig, body),

  //Offers

  updatePaymentMessage: (body, reqConfig) => callApi('update-payment-messages', reqConfig, body),
  paymentOffersList: (body, reqConfig) => callApi('payment-offer-list', reqConfig, body),                    //done
  couponOffersList: (body, reqConfig) => callApi('coupon-offer-list', reqConfig, body),                      //done
  cashBackOfferDetail: (body, reqConfig) => callApi('payment-gateway-list', reqConfig, body),                //done
  offerCouponList: (body, reqConfig) => callApi('coupon-promo-list', reqConfig, body),                       //done
  couponOfferCreate: (body, reqConfig) => callApi('coupon-offer-create', reqConfig, body),                   //done
  paymentOfferCreate: (body, reqConfig) => callApi('payment-offer-create', reqConfig, body),                 //done
  couponOffersDetail: (body, reqConfig) => callApi('coupon-offer-detail', reqConfig, body),                  //done
  paymentOffersDetail: (body, reqConfig) => callApi('payment-offer-detail', reqConfig, body),                //done
  couponOffersDelete: (body, reqConfig) => callApi('coupon-offer-delete', reqConfig, body),                  //done
  paymentOffersDelete: (body, reqConfig) => callApi('payment-offer-delete', reqConfig, body),                //done
  cleanAndroidCatch: (body, reqConfig) => callApi('clean-android-catch', reqConfig, body),                   //done
  getLastCacheClearDate: (body, reqConfig) => callApi('get-last-cache-clear-date', reqConfig, body),          //done

  platformsList: (body, reqConfig) => callApi('platforms-list', reqConfig, body),                            //done
  cardOfferCreate: (body, reqConfig) => callApi('card-discount-create', reqConfig, body),
  cardOffersList: (body, reqConfig) => callApi('card-discount-list', reqConfig, body),
  cardOffersDelete: (body, reqConfig) => callApi('card-discount-delete', reqConfig, body),
  cardOffersDetail: (body, reqConfig) => callApi('card-discount-detail', reqConfig, body),

  //Mobile Landing Page
  getMobileLandingPage: (body, reqConfig) => callApi('get-mobile-landing-page', reqConfig, body),
  createMobileLandingPage: (body, reqConfig) => callApi('create-mobile-landing-page', reqConfig, body),
  detailMobileLandingPage: (body, reqConfig, id) => callApi(`detail-mobile-landing-page/${id}`, reqConfig, body),
  updateMobileLandingPage: (body, reqConfig, id) => callApi(`update-mobile-landing-page/${id}`, reqConfig, body),
  imageUploader: (body, reqConfig) => callApi('image-uploader', reqConfig, body),
  deleteMobileLandingPage: (body, reqConfig) => callApi('delete-mobile-landing-page/', reqConfig, body),
  mobileLandingPositionChange: (body, reqConfig) => callApi('moble-landing-position-change', reqConfig, body),

  //Category Section
  getCategoryBanner: (body, reqConfig) => callApi('get-category-banner', reqConfig, body),
  updateCategoryBanner: (body, reqConfig) => callApi('update-category-banner', reqConfig, body),
  addCustomerStory: (body, reqConfig) => callApi('add-customer-story', reqConfig, body),
  deleteCustomerStory: (body, reqConfig) => callApi('delete-customer-story', reqConfig, body),
  detailCustomerStory: (body, reqConfig) => callApi('detail-customer-story', reqConfig, body),
  updateCustomerStory: (body, reqConfig) => callApi('update-customer-story', reqConfig, body),


  // Blog Section
  getBlog: (body, reqConfig) => callApi('get-wpblog-list', reqConfig, body),
  createBlog: (body, reqConfig) => callApi('create-wpblog', reqConfig, body),
  detailBlogPage: (body, reqConfig, id) => callApi(`detail-wpblog-list/${id}`, reqConfig, body),
  updateBlogPage: (body, reqConfig, id) => callApi(`update-wpblog/${id}`, reqConfig, body),
  deleteBlog: (body, reqConfig) => callApi('delete-wpblog-data', reqConfig, body),
  blogPositionChange: (body, reqConfig) => callApi('blog-position-change', reqConfig, body),             //done



  //Theme Section
  createArtist: (body, reqConfig) => callApi('artist-create', reqConfig, body),
  getArtist: (url, reqConfig) => callApiGet(url, reqConfig),
  getEditArtist: (body, reqConfig, id) => callApiGet(`artist-list/${id}`, reqConfig),
  deleteArtist: (body, reqConfig, id) => callApiDelete(`artist-delete/${id}`, reqConfig, body),

  themePositionChange: (body, reqConfig) => callApi('artist-position-change', reqConfig, body),

  createOccassion: (body, reqConfig) => callApi('occasion-create', reqConfig, body),
  getOccassion: (url, reqConfig) => callApiGet(url || `occasion-list/`, reqConfig,),
  getEditOccassion: (body, reqConfig, id) => callApiGet(`occasion-list/${id}`, reqConfig, body),
  deleteOccassion: (body, reqConfig, id) => callApiDelete(`occasion-delete/${id}`, reqConfig, body),

  occassionPositionChange: (body, reqConfig) => callApi('occasion-position-change', reqConfig, body),
  recipientPositionChange: (body, reqConfig) => callApi('recipient-position-change', reqConfig, body),
  themeCategoryPositionChange: (body, reqConfig) => callApi('theme-cateogry-position-change', reqConfig, body),
  themePositionChange: (body, reqConfig) => callApi('theme-position-change', reqConfig, body),



  createRecepient: (body, reqConfig) => callApi('recipient-create', reqConfig, body),
  getRecepient: (body, reqConfig) => callApiGet(`recipient-list/`, reqConfig, body),
  getEditRecepient: (body, reqConfig, id) => callApiGet(`recipient-list/${id}`, reqConfig, body),
  deleteRecepient: (body, reqConfig, id) => callApiDelete(`recipient-delete/${id}`, reqConfig, body),

  createCategory: (body, reqConfig) => callApi('theme-category-create', reqConfig, body),
  getCategory: (body, reqConfig) => callApiGet(`theme-category-list/`, reqConfig, body),
  getEditCategory: (body, reqConfig, id) => callApiGet(`theme-category-list/${id}`, reqConfig, body),
  deleteCategory: (body, reqConfig, id) => callApiDelete(`theme-category-delete/${id}`, reqConfig, body),


  getProduct: (body, reqConfig) => callApiGet(`get-product-names`, reqConfig, body),


  createTheme: (body, reqConfig) => callApi('theme-create', reqConfig, body),
  getTheme: (url, body, reqConfig) => callApi(url, reqConfig, body),
  getEditTheme: (body, reqConfig, id) => callApi(`theme-list/${id}`, reqConfig, body),



  createProductTheme: (body, reqConfig) => callApi('text-display-product-theme-create', reqConfig, body),
  getProductTheme: (body, reqConfig) => callApiGet(`text-display-product-theme-list/`, reqConfig, body),
  getEditProductTheme: (body, reqConfig, id) => callApiGet(`text-display-product-theme-list/${id}`, reqConfig, body),



  //new category

  //product
  getNewCategoryList: (reqConfig) => callApiGet('categories/', reqConfig),                                          //done
  getCategoryDetail: (body, reqConfig, id) => callApiGet(`categories/${id}`, reqConfig, body),                            //done
  // productPriceDetail: (body, reqConfig) => callApi('product-price-detail', reqConfig, body),                 //done
  categoryEdit: (body, reqConfig, id) => callApi(`categories/${id}`, reqConfig, body),

  //footer section
  getNewFooterList: (tag, reqConfig) => callApiGet(tag, reqConfig),
  footerEdit: (body, reqConfig) => callApi('static-page-detail', reqConfig, body),
  seoFooterDelete: (tag, reqConfig) => callApiDelete(tag, reqConfig),

  //seo meta section
  getSEOMetaList: (tag, reqConfig) => callApiGet(tag, reqConfig),
  SEOMetaEdit: (body, reqConfig) => callApi('seo-detail-save', reqConfig, body),
  seoMetaDelete: (tag, reqConfig) => callApiDelete(tag, reqConfig),

  getManifestList: (tag, reqConfig) => callApiGet(tag, reqConfig),
  SEOManifestEdit: (body, reqConfig) => callApi('manifest-create', reqConfig, body),
  seoManifestDelete: (tag, reqConfig) => callApiDelete(tag, reqConfig),

  getRetargetConfig: (tag, reqConfig) => callApiGet(tag, reqConfig),
  retargetConf: (body, reqConfig) => callApi('retarget-config', reqConfig, body),

  getFeedbackAll: (body, reqConfig) => callApi(`get-product-reviews-list`, reqConfig, body),
  getFeedback: (id, reqConfig) => callApiGet(`get-product-reviews-edit/${id}`, reqConfig),
  updateFeedback: (body, reqConfig) => callApi(`update-product-review-customer-support`, reqConfig, body),
  saveFeedback: (id, reqConfig) => callApiGet(`product-review-visibility/${id}`, reqConfig),

  getVendorList: (reqConfig) => callApiGet(`list-vendor`, reqConfig),
  getVendor: (id, reqConfig) => callApiGet(`get-vendor-by-id/${id}`, reqConfig),
  vendorDelete: (tag, reqConfig) => callApiDelete(tag, reqConfig),
  createVendor: (body, reqConfig) => callApi('create-vendor', reqConfig, body),
  placeOrderVendor: (body, reqConfig) => callApi('place-order-for-vendor', reqConfig, body),

};

export default Api
