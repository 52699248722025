import React, { Component } from 'react';
import { ADMIN_API_HEADER, API_HEADER } from "../config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Api from "../service/Api";
import { withRouter } from 'react-router-dom'
import axios, { AxiosError as error } from "axios/index";
import 'react-input-range/lib/css/index.css'
import "./stylesheet.css"
import fontFamily from "../service/FontFamily";
import { Textfit } from 'react-textfit';
import AvatarEditor from 'react-avatar-editor'
import InputRange from "react-input-range";
import Helpers from "../service/Helpers";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { confirmAlert } from "react-confirm-alert";
import logo from './../assets/img/icons/aspectFit.png';
import check from './../assets/img/icons/check.svg';


class OrdersEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutJson: [],
      slug: "",
      version: "",
      imageEditor: false, editStatus: true
    };
    this.validator = new SimpleReactValidator();
    this.validatorOrderStatus = new SimpleReactValidator();

  }
  componentDidMount() {
    let id = this.props.match.params.orderId;
    if (id) {
      let reqBody = {
        id: id,
      };
      Api.getSubOrderJson(reqBody, ADMIN_API_HEADER).then((response) => {
        if (response.status === 200) {
          if (response.data.status === 0) {
            let orderDetail = response.data.data[0]
            let orderJson = orderDetail.order_json.pages
            this.setState({
              orderJsonData: JSON.stringify(orderJson),
              orderDetail,
              order: orderJson
            })
            let templateUrl = `https://s3.ap-south-1.amazonaws.com/zoomin-new/live-product/${orderDetail.order_json.id}/${orderDetail.order_json.version}/template.json`
            axios.get(templateUrl, {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "cache-control": "no-cache"
              }
            }).then(templateResponse => {
              if (templateResponse.status === 200) {
                let layoutJsonData = templateResponse.data.theme ? templateResponse.data.theme.filter(arr => arr.themeid === orderDetail.order_json.themeid)[0] : templateResponse.data.pages
                let layoutJsonTheme = [], pageJson = [], layoutJson = []
                if (layoutJsonData.cover) {
                  layoutJsonTheme.push(layoutJsonData.cover[0])
                }
                layoutJsonTheme = layoutJsonTheme.concat(layoutJsonData.pages ? layoutJsonData.pages : templateResponse.data.layouts ? templateResponse.data.layouts : layoutJsonData.layouts ? layoutJsonData.layouts : layoutJsonData)
                let pages = orderDetail.order_json.pages
                pages.forEach((page) => {
                  layoutJson.push(layoutJsonTheme.filter(arr => arr.id === page.id)[0])
                })
                //  pages.forEach((page)=>{
                //   layoutJson.push(page)
                // })
                pages.map((newLayout, i) => {
                  if(i!==0 && (!newLayout.displayTruncatingOffset && !newLayout.placeholders[0].displayTruncatingOffset)){
                    layoutJson[i] = {...layoutJson[i], backgroundId :newLayout.backgroundId}
                  }
                })
              
                console.log(pages,layoutJson);
                // let sticker=pages[1].stickers;
                fontFamily(templateResponse.data.template.id, templateResponse.data.template.version)
                let templateFrameWidth, templateFrameHeight
                templateFrameWidth = templateResponse.data.template.size[layoutJsonData && layoutJsonData.orientation && layoutJsonData.orientation === "landscape" ? 1 : 0]
                templateFrameHeight = templateResponse.data.template.size[layoutJsonData && layoutJsonData.orientation && layoutJsonData.orientation === "landscape" ? 0 : 1]
                this.setState({ pageJson: pages, layoutJson, templateJson: templateResponse.data.template, slug: templateResponse.data.template.id, version: templateResponse.data.template.version, templateFrameWidth, templateFrameHeight })

              }
            })


          }
          else {
            this.setState({ msg: response.data.message, editStatus: false });
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000
            });
          }
        }
        else {
          this.setState({ msg: response.data.message, editStatus: false });
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      })
    }
  }
  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextState.pageJson && nextProps.match.params.i && nextProps.match.params.j && (nextProps.match.params.i !== this.state.iPos || nextProps.match.params.j !== this.state.jPos)) {
      this.setState({
        iPos: nextProps.match.params.i,
        jPos: nextProps.match.params.j
      })
      let canvasScale = nextState.layoutJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].frame[2] / nextState.layoutJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].frame[3]

      //fetch(`https://cdn.filestackcontent.com/AS6IcbWmTSZK2jYLeo85nz/rotate=deg:exif/imagesize/${nextState.pageJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].photourl.replace('https://cdn.filestackcontent.com/', '')}`)
      axios.get(`https://d1boicrasyl7jn.cloudfront.net/zoominuploader_imageprocess_pdfer?onlymetadata=true&key=${nextState.pageJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].photourl}`, {})

        // .then(res => res.json())
        .then((res) => {
          let data = res.data
          let localImage = nextState.pageJson[nextProps.match.params.i].placeholders[nextProps.match.params.j]
          let value = localImage.zoomScale;
          let x = localImage.frame[0], y = localImage.frame[1], cropWidth = localImage.frame[2], cropHeight = localImage.frame[3]
          if (localImage.degree === 90) {
            let ox = localImage.frame[0]
            let oy = localImage.frame[1]
            x = oy;
            y = data.height - (ox + cropWidth);
            cropWidth = localImage.frame[3];
            cropHeight = localImage.frame[2];
          }
          else if (localImage.degree === 180) {
            let ox = x
            let oy = y
            x = data.width - (cropWidth + ox);
            y = (data.height - (cropHeight + oy));
          }
          else if (localImage.degree === 270) {
            let ox = x
            let oy = y
            x = data.width - (cropHeight + oy);
            y = ox;
            cropWidth = localImage.frame[3];
            cropHeight = localImage.frame[2];
          }
          x = x / data.width
          y = y / data.height
          let cWidth = cropWidth / data.width
          let cHeight = cropHeight / data.height
          let centX = cWidth / 2;
          let centY = cHeight / 2;
          let position
          if (localImage.frame[0] === 0 && localImage.frame[1] === 0 && localImage.frame[2] === 0 && localImage.frame[3] === 0) {
            position = { x: 0.5, y: 0.5 }
          }
          else {
            position = { x: x + centX, y: y + centY }
          }
          console.log(data);
          this.setState({
            //image: localImage.photourl.replace('https://cdn.filestackcontent.com/', ''),
            image: localImage.photourl,
            position: position,
            scale: value,
            value: value,
            rotate: localImage.degree,
            width: data.width,
            height: data.height,
            canvasScale: canvasScale,
            imageEditor: true
          })
        })
    }
    if (!this.props.match.params.i && !this.props.match.params.j && nextProps.match.params.i && nextProps.match.params.j) {
      this.setState({ scroll: window.scrollY })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.location.pathname !== nextProps.location.pathname && !nextProps.match.params.i) {
      this.setState({
        image: "",
        position: "",
        scale: "",
        value: "",
        rotate: "",
        width: "",
        height: "",
        canvasScale: "",
        iPos: "",
        jPos: ""
      })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let pageWidth = document.getElementsByClassName("editor-content")
    if (pageWidth[0] && prevState.widthPage !== pageWidth[0].clientWidth) {
      this.setState({ widthPage: pageWidth[0].clientWidth })
    }
    console.log(this.state.pageJson)
    if (this.state.orderJsonData !== JSON.stringify(this.state.pageJson) && this.state.generatePdf === false) {
      this.setState({ generatePdf: true })
    }
    if (this.state.orderJsonData !== JSON.stringify(this.state.pageJson) && this.state.reprocessOrder === false) {
      this.setState({ reprocessOrder: true })
    }
    if (!this.props.match.params.i && !this.props.match.params.j && prevProps.match.params.i && prevProps.match.params.j) {
      console.log(prevProps.scroll, this.state.scroll);
      window.scrollTo(0, this.state.scroll)
    }
  }
  redirectImageEditor = (i, j, width, height, img, th, tw) => {
    console.log("abcd");
    this.props.history.push({
      pathname:
        "/preview/" + this.props.match.params.orderId + "/edit/" + i + "/" + j,
      state: {
        image: img,
        th: th,
        tw: tw,
         width:width,
       height:height
      }
    })
  }

  handlePositionChange = position => {
    this.setState({ position })
  }
  setEditorRef = (editor) => this.editor = editor
  rotateLeft = () => {
    this.setState({
      value: 1,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
    })
    if (this.state.rotate !== 0) {
      this.setState({
        rotate: this.state.rotate - 90
      })
    } else {
      this.setState({
        rotate: 270
      })
    }
  }
  rotateRight = () => {
    this.setState({
      value: 1,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
    })
    if (this.state.rotate !== 270) {
      this.setState({
        rotate: this.state.rotate + 90
      })
    } else {
      this.setState({
        rotate: 0
      })
    }

  }
  clickSave = () => {
    if (this.editor) {
      const data = this.editor.getCroppingRect()
      let x = data.x;
      let y = data.y;
      let cropWidth = data.width;
      let cropHeight = data.height;
      let width = this.state.width;
      let height = this.state.height;
      let scale = this.state.value;
      let rotate = this.state.rotate;
      let imageResize = Helpers.imageResize(rotate, width, height, x * width, y * height, cropWidth * width, cropHeight * height)
      if ((imageResize.x || imageResize.x === 0) && (imageResize.y || imageResize.y === 0) && imageResize.cropWidth && imageResize.cropHeight) {
        let arr = this.state.pageJson;
        arr[this.state.iPos].placeholders[this.state.jPos].frame = [
          parseInt(imageResize.x),
          parseInt(imageResize.y),
          parseInt(imageResize.cropWidth),
          parseInt(imageResize.cropHeight)
        ]
        arr[this.state.iPos].placeholders[this.state.jPos].degree = rotate;
        arr[this.state.iPos].placeholders[this.state.jPos].zoomScale = scale;
        this.setState({
          pageJson: arr,
          image: "",
          position: "",
          scale: "",
          value: "",
          rotate: "",
          width: "",
          height: "",
          canvasScale: "",
          imageLoaded: false
        })

        this.props.history.push(`/preview/${this.props.match.params.orderId}`)
      }
    }
  }
  clickSavePageJson = () => {
    let order_json = this.state.orderDetail.order_json
    order_json.pages = this.state.pageJson
    let reqBody = { id: this.props.match.params.orderId, order_json: order_json }
    Api.updateSubOrderJson(reqBody, ADMIN_API_HEADER).then((response) => {
      if (response.status === 200) {
        if (response.data.status === 0) {
          this.setState({ orderJsonData: JSON.stringify(this.state.pageJson) })
        }
        else {

          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        });
      }
    }).catch((error) => {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      });
    })
  }
  onImageReady = () => {
    this.setState({ imageLoaded: true })
  }
  generatePdf = () => {
    let reqBody = {
      sub_order_id: this.props.match.params.orderId,
    };
    this.setState({ generatePdf: false })
    Api.generatePDF(reqBody, ADMIN_API_HEADER).then((response) => {
      this.setState({ dataToCome: 1 })
      if (response.status === 200) {
        if (response.data.status === 0) {
          for (let i = 0; i < response.data.data.pdf_url.length; i++) {
            setTimeout(function (path) {
              window.open(path)
            }, 200, response.data.data.pdf_url[i]);
          }
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }

      } else {
        this.setState({
          submit_btn: true
        });
        this.setState({ msg: response.data.message });
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        });
      }
    })
  };
  changeStatus = () => {
    this.setState({
      changeSubOrderNote: "",
      modal: !this.state.modal,
    });
    this.validatorOrderStatus.hideMessages()
  }
  handleChangeSubOrderStatus = (e) => {
    e.preventDefault();
    let id = this.state.orderDetail.id
    let reqBody = {
      id: parseInt(id),
      order_status: "reprocessed",
      notes: this.state.changeSubOrderNote
    }
    if (this.validatorOrderStatus.allValid()) {
      this.setState({ modal: false })
      confirmAlert({
        title: 'Confirm',
        message: `Are you sure you want to change order status?`,
        buttons: [
          {
            label: `Yes`,
            onClick: () => {
              this.setState({ reprocessOrder: false })
              Api.changeSubOrderStatus(reqBody, ADMIN_API_HEADER).then((response) => {
                if (response.status === 200) {
                  if (response.data.status === 0) {
                    toast.success(response.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1500
                    });
                  }
                  else {
                    toast.error(response.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 3000
                    });
                  }
                }
                else {
                  this.setState({ msg: response.data.message });
                  toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
                }
              })
            }
          },
          {
            label: 'No',
            onClick: () => {
              this.setState({
                changeSubOrderNote: ""
              });
            }
          }
        ]
      })
    }
    else {
      this.validatorOrderStatus.showMessages();
      this.forceUpdate();
    }
  }
  handleSubOrder = (event, name) => {
    this.setState({ [name]: event.target.value }, () => {

    })
  }

  toggleAspectFitFill = (i) => {
    const aspectList = [
      "prints_4x4", "prints_5.5x5.5", "prints_4x4_premium", "prints_4x4_prglossy", "prints_5.5x5.5_prglossy", "prints_3x3", "prints_2.3x3.5","prints_3x3_premium","prints_3x3_prglossy",
      "softcover_5.5_book_32",
      "softcover_5.5_book_20",
      "hardcover_5.5_book_premium_20",
      "hardcover_5.5_book_premium_32",
      "softcover_5.5_book_prmatte_20",
      "softcover_5.5_book_prmatte_32",
      "softcover_5.5_book_premium_20",
      "softcover_5.5_book_premium_32",
      "hardcover_5.5_book_20",
      "hardcover_5.5_book_32",
      "digital_5.5_book_20",
      "digital_5.5_book_32",]
    let pages = this.state.pageJson;
    let placeholders = [...pages[i].placeholders];
    placeholders.map((placeholder, j) => {
      pages[i].placeholders[j] = {
        ...placeholder,
        frame: [0, 0, 0, 0],
        zoomScale: 1,
        contentMode:
          placeholder.contentMode === "aspectFill"
            ? "aspectFit"
            : aspectList.includes(this.state.slug) &&
              !placeholder.contentMode
              ? "aspectFill"
              : "aspectFill",
      };
    });
    this.setState({ pageJson: pages });
    if (this.props.cartid || this.props.creationid) {
      let state = this.props.history.location.state;
      this.props.history.replace({
        pathname: this.props.history.location.pathname,
        state: { ...state, pages },
      });
    }
    // else {
    //   this.createLocalJson("edit", pages);
    //   let editPage = this.getLocalJson("editPage")
    //   editPage[i] = pages[i]
    //   this.createLocalJson("editPage", editPage);
    // }
  };





  startTouch(event, i, j) {
    let container = document.getElementsByClassName("stickerContent")[i];
    let obj = document.getElementsByClassName("image-inside")[i];
    var touch = event.targetTouches[0]
    let x = touch.clientX;
    let y = touch.clientY;
    let pages = this.state.pageJson;
    let sticker = [...pages[j].stickers];
    console.log(sticker)
    //let sticker = this.state.sticker
    let stickerAtPosition = sticker[i]
    sticker.splice(i, 1);
    sticker.push(stickerAtPosition)
    this.setState({ sticker, activePosition: sticker.length - 1 })
    this.setState({ mousePositionStart: { x, y }, stickerStart: { i: sticker.length - 1, x: container.style.left ? container.style.left : 0, y: container.style.top ? container.style.top : 0, j: j } })
    document.addEventListener('touchmove', this.getTouchPoint);
    obj.ondragstart = function () {
      return false;
    };
    // this.setState({mousePositionStart: {x, y}, stickerStart:{x: obj.style.left? obj.style.left : 0, y: obj.style.top ? obj.style.top : 0}})
    //
    // document.addEventListener('touchmove', this.getTouchPoint);
    // obj.ontouchend = ()=> {
    //   document.removeEventListener('touchmove', this.getTouchPoint);
    //   this.setState({mousePositionStart: null, stickerStart: null})
    //   obj.onmouseup = null;
    // };
  }
  endResizeTouch = (i, j) => {
    let obj = document.getElementsByClassName("image-inside")[i];
    document.removeEventListener('touchmove', this.getTouchPoint);
    this.setState({ mousePositionStart: null, stickerStart: null })
    obj.onTouchEnd = null;
    // if (this.props.cartid === undefined && this.props.creationid === undefined){
    //   let stickerArray=this.getLocalJson("editPageSticker")
    //   stickerArray[this.props.page] = this.state.sticker
    //   this.createLocalJson("editPageSticker", stickerArray)
    // }
    // else {
    //   let pages = this.props.history.location.state.pageJson;
    //   pages[this.props.page].stickers = this.state.sticker
    //   this.props.history.replace({
    //     pathname: this.props.history.location.pathname,
    //     state: { ...this.props.history.location.state, pages },
    //   });
    // }
    this.state.pageJson[j].stickers = this.state.sticker
  }
  rotateImage(event, i, j) {
    let sticker = this.state.sticker
    // let container = document.getElementsByClassName("stickerContent")[j];
    // let obj = document.getElementsByClassName("rotate-controller")[j];
    let container = document.getElementsByClassName(`stickerContent_${j}_${i}`)[0];
    let obj = document.getElementsByClassName(`rotate-controller_${j}_${i}`)[0];
    console.log("rotate",obj);
    let x = event.pageX;
    let y = event.pageY;
    let width = container.clientWidth
    let height = container.clientHeight
    let x2, y2
    x2 = x - ((width / 2) * Math.cos(sticker[i].degree * (Math.PI / 180))) + ((height / 2) * Math.sin(sticker[i].degree * (Math.PI / 180)))
    y2 = y - ((height / 2) * Math.sin(sticker[i].degree * (Math.PI / 180))) - ((height / 2) * Math.cos(sticker[i].degree * (Math.PI / 180)))
    x2 = x2 - window.pageXOffset
    y2 = y2 - window.pageYOffset
    this.setState({ mousePositionStart: { i: sticker.length - 1, x, center_x: x2, y, center_y: y2, left: container.style.left, top: container.style.top, width, height, rotate: sticker[0].rotate, j: j } })
    document.addEventListener('mousemove', this.getRotatePoint);
    obj.onmouseup = () => {
      document.removeEventListener('mousemove', this.getRotatePoint);
      this.setState({ mousePositionStart: null, stickerStart: null })
      obj.onmouseup = null;
      //   if (this.props.cartid === undefined && this.props.creationid === undefined){
      //     let stickerArray=this.getLocalJson("editPageSticker")
      //     stickerArray[this.props.page] = this.state.sticker
      //     this.createLocalJson("editPageSticker", stickerArray)
      //   }
      //   else {
      //     let pages = this.props.history.location.state.pageJson;
      //     pages[this.props.page].stickers = this.state.sticker
      //     this.props.history.replace({
      //       pathname: this.props.history.location.pathname,
      //       state: { ...this.props.history.location.state, pages },
      //     });
      //   }
    };
    this.state.pageJson[j].stickers = this.state.sticker
  }
  rotateTouch(event, i, j) {
    let sticker = this.state.sticker
    let container = document.getElementsByClassName("stickerContent")[i];
    let obj = document.getElementsByClassName("rotate-controller")[i];

    var touch = event.targetTouches[0]
    let x = touch.pageX;
    let y = touch.pageY;
    let width = container.clientWidth
    let height = container.clientHeight
    let x2, y2
    x2 = x - ((width / 2) * Math.cos(sticker[i].degree * (Math.PI / 180))) + ((height / 2) * Math.sin(sticker[i].degree * (Math.PI / 180)))
    y2 = y - ((height / 2) * Math.sin(sticker[i].degree * (Math.PI / 180))) - ((height / 2) * Math.cos(sticker[i].degree * (Math.PI / 180)))
    x2 = x2 - window.pageXOffset
    y2 = y2 - window.pageYOffset
    this.setState({ mousePositionStart: { i: sticker.length - 1, x, center_x: x2, y, center_y: y2, left: container.style.left, top: container.style.top, width, height, rotate: sticker[0].rotate, j: j } })
    document.addEventListener('touchmove', (e) => this.getTouchRotatePoint(e, obj));
    // obj.touchend = ()=> {
    //   console.log("000000000000")
    //   document.removeEventListener('touchmove', this.getTouchRotatePoint);
    //   this.setState({mousePositionStart: null, stickerStart: null})
    //   obj.onmouseup = null;
    //   if (this.props.cartid === undefined && this.props.creationid === undefined){
    //     let stickerArray=this.getLocalJson("editPageSticker")
    //     stickerArray[this.props.page] = this.state.sticker
    //     this.createLocalJson("editPageSticker", stickerArray)
    //   }
    //   else {
    //     let pages = this.props.history.location.state.pageJson;
    //     pages[this.props.page].stickers = this.state.sticker
    //     this.props.history.replace({
    //       pathname: this.props.history.location.pathname,
    //       state: { ...this.props.history.location.state, pages },
    //     });
    //   }
    // };
    // obj.ondragstart = function() {
    //   return false;
    // };

  }
  endRotateTouch = (i, j) => {
    let obj = document.getElementsByClassName("rotate-controller")[i];
    document.removeEventListener('touchmove', this.getTouchRotatePoint);
    this.setState({ mousePositionStart: null, stickerStart: null })
    obj.onTouchEnd = null;
    // if (this.props.cartid === undefined && this.props.creationid === undefined){
    //   let stickerArray=this.getLocalJson("editPageSticker")
    //   stickerArray[this.props.page] = this.state.sticker
    //   this.createLocalJson("editPageSticker", stickerArray)
    // }
    // else {
    //   let pages = this.props.history.location.state.pageJson;
    //   pages[this.props.page].stickers = this.state.sticker
    //   this.props.history.replace({
    //     pathname: this.props.history.location.pathname,
    //     state: { ...this.props.history.location.state, pages },
    //   });
    // }
    this.state.pageJson[j].stickers = this.state.sticker
  }

  startMouse(event, i, j) {

   
    let container = document.getElementsByClassName(`stickerContent_${j}_${i}`)[0];
    let obj = document.getElementsByClassName(`image-inside_${j}_${i}`)[0];

    console.log("======OBJ", obj)
    console.log("======CONT", container)

    let x = event.clientX;
    let y = event.clientY;
    let pages = this.state.pageJson;
    let sticker = [...pages[j].stickers];
    console.log(sticker)

    //let sticker = this.state.sticker
    let stickerAtPosition = sticker[i]
    sticker.splice(i, 1);
    sticker.push(stickerAtPosition)
    this.setState({ sticker, activePosition: j })
    this.setState({
      mousePositionStart: { x, y }
    }, () => {
      console.log("====mousePositionStart", this.state.mousePositionStart)
    })
    this.setState({
      stickerStart: {
        i: sticker.length - 1,
        j: j,
        x: container.style.left ? container.style.left : 0,
        y: container.style.top ? container.style.top : 0
      }
    }, () => {
      console.log("====SETstickerStart", this.state.stickerStart)
    })

    console.log("====mousePositionStart", this.state.mousePositionStart)

    document.addEventListener('mousemove', this.getMousePoint);

    obj.onmouseup = () => {
      console.log("====OnMouseUp", obj)
      document.removeEventListener('mousemove', this.getMousePoint);
      this.setState({ mousePositionStart: null, stickerStart: null })
      obj.onmouseup = null;
    };
    obj.ondragstart = () => {
      console.log("====OnDragStart", obj)
      return false;
    };
  }


  getMousePoint = (event) => {
    let x = event.clientX;
    let y = event.clientY;
    this.setSticker(x, y)
  }
  getTouchPoint = (event) => {
    let touch = event.touches[0];
    let x = touch.clientX;
    let y = touch.clientY;
    this.setSticker(x, y)
  }
  getResizePoint = (event, direction) => {
    let x = event.clientX;
    let y = event.clientY;
    this.resizeSticker(x, y, direction)
  }
  getRotatePoint = (event) => {
    let x = event.clientX;
    let y = event.clientY;
    this.rotateSticker(x, y, event.clientX, event.clientY)
  }
  getTouchRotatePoint = (event, obj) => {
    // obj.touchend(()=>{
    //   alert()
    // })
    let touch = event.touches[0];
    let x = touch.clientX;
    let y = touch.clientY;
    this.rotateSticker(x, y, x, y)
  }
  setSticker = (clientX, clientY) => {
    let container = document.getElementsByClassName("sticker")[0];
    var x = clientX;
    var y = clientY;
    let mousePositionStart = this.state.mousePositionStart
    console.log("=====@@@@@@@@@@@this.state", this.state)
    if (this.state && this.state.stickerStart) {
      let startSticker = this.state.stickerStart
      console.log("=====STRTSTICKER", startSticker)
      let i = startSticker.i
      console.log("=====container", container)
      let containerWidth = container.clientWidth
      let containerHeight = container.clientHeight
      let sticker = this.state.sticker
      let moveLeft = (containerWidth * (parseInt(startSticker.x.slice(0, -1)) / 100)) + (x - mousePositionStart.x)
      let moveTop = (containerHeight * (parseInt(startSticker.y.slice(0, -1)) / 100)) + (y - mousePositionStart.y)
      console.log("====moveLeft", moveLeft)
      console.log("====containerWidth", containerWidth)

      let percentageLeft = moveLeft / containerWidth
      let percentageTop = moveTop / containerHeight
      let tempSize = this.state.templateJson.size;
      let templateWidth = tempSize[this.state.layoutJson[startSticker.j].orientation == "landscape" ? 1 : 0];
      let templateHeight = tempSize[this.state.layoutJson[startSticker.j].orientation == "landscape" ? 0 : 1];
      console.log("====templateWidth", templateWidth)
      console.log("====percentageLeft", percentageLeft)
      console.log("====sticker[i].frame[2]", sticker[i])
      sticker[i].frame[0] = (templateWidth * percentageLeft) + (sticker[i].frame[2] / 2)
      sticker[i].frame[1] = (templateHeight * percentageTop) + (sticker[i].frame[3] / 2)
      console.log("==========================STICKKKKER", sticker);
      this.setState({ sticker })
    }


  }

  rotateSticker = (clientX, clientY, x, y) => {
    let container = document.getElementsByClassName("sticker")[0];
    let mousePositionStart = this.state.mousePositionStart
    let sticker = this.state.sticker
    var mouse_x = clientX; var mouse_y = clientY;
    let distanceFromDragCenter = Math.sqrt(Math.pow((mouse_x - mousePositionStart.center_x), 2) + Math.pow((mouse_y - mousePositionStart.center_y), 2))
    let distanceFromCenter = Math.sqrt(Math.pow((mousePositionStart.x - mousePositionStart.center_x), 2) + Math.pow((mousePositionStart.y - mousePositionStart.center_y), 2))
    let size = Math.sqrt(Math.pow((2 * distanceFromDragCenter), 2) / 2)
    let centerDiff = distanceFromDragCenter - distanceFromCenter
    let containerWidth = container.clientWidth
    let containerHeight = container.clientHeight
    var radians = Math.atan2(mouse_x - mousePositionStart.center_x, mouse_y - mousePositionStart.center_y);
    var degree = (radians * (180 / Math.PI) * -1) + 45;
    degree = degree < 0 ? 360 + parseInt(degree) : degree
    this.setState({ angleSticker: degree })
    let templateWidth =
      this.state.templateJson.size[
      this.state.layoutJson[mousePositionStart.j].orientation === "landscape" ? 1 : 0
      ],
      templateHeight =
        this.state.templateJson.size[
        this.state.layoutJson[mousePositionStart.j].orientation === "landscape" ? 0 : 1
        ];
    let moveLeft = (containerWidth * (parseInt(mousePositionStart.left.slice(0, -1)) / 100)) - ((size - mousePositionStart.width) / 2)
    let moveTop = (containerHeight * (parseInt(mousePositionStart.top.slice(0, -1)) / 100)) - ((size - mousePositionStart.height) / 2)
    let percentageLeft = (moveLeft / containerWidth)
    let percentageTop = (moveTop / containerHeight)
    let percentageWidth = (size) / containerWidth
    let percentageHeight = (size) / containerHeight
    let width = templateWidth * percentageWidth
    let height = templateHeight * percentageHeight
    let top = templateHeight * (percentageTop + (percentageHeight / 2))
    let left = templateWidth * (percentageLeft + (percentageWidth / 2))
    sticker[sticker.length - 1].frame[2] = width
    sticker[sticker.length - 1].frame[3] = height
    sticker[sticker.length - 1].frame[0] = left
    sticker[sticker.length - 1].frame[1] = top
    sticker[sticker.length - 1].degree = degree
    this.setState({ sticker })
  }






  render() {
    const { pageJson, layoutJson, templateJson, slug, version, templateFrameWidth, templateFrameHeight, widthPage, orderDetail, orderJsonData, activePosition, } = this.state
    const { canvasScale, template, position, scale, rotate, value, iPos, jPos, generatePdf, reprocessOrder } = this.state;
    let defaultValue = window.outerHeight / 3.2
    if (canvasScale < 1) {
      defaultValue = window.outerHeight / 2.4
    }
    else if (canvasScale === 1) {
      defaultValue = window.outerHeight / 2.3
    }
    else if (canvasScale > 2) {
      defaultValue = window.outerHeight / 5.2
    }
    let width, height;
    if (rotate === 90 || rotate === 270) {
      width = defaultValue;
      height = defaultValue * canvasScale
    } else {
      width = defaultValue * canvasScale
      height = defaultValue
    }
    console.log(this.state, this.props)
    const aspectList = [
      "prints_4x4", "prints_5.5x5.5", "prints_4x4_premium", "prints_4x4_prglossy", "prints_5.5x5.5_prglossy", "prints_3x3","prints_3x3_premium","prints_3x3_prglossy",
      "softcover_5.5_book_32",
      "softcover_5.5_book_20",
      "hardcover_5.5_book_premium_20",
      "hardcover_5.5_book_premium_32",
      "softcover_5.5_book_prmatte_20",
      "softcover_5.5_book_prmatte_32",
      "softcover_5.5_book_premium_20",
      "softcover_5.5_book_premium_32",
      "hardcover_5.5_book_20",
      "hardcover_5.5_book_32",
      "digital_5.5_book_20",
      "digital_5.5_book_32",]




    return (

      <div>
        <Modal isOpen={this.state.modal} toggle={this.changeStatus} className={this.props.className}
          unmountOnClose={this.state.unmountOnClose}>
          <ModalHeader
            toggle={this.toggle}>Change Order Status</ModalHeader>
          <ModalBody>
            <form onSubmit={evt => this.handleChangeSubOrderStatus(evt)}>
              <div className={"row"}>
                <div className={"col-12 form-group"}>
                  <label>Add note</label>
                  <textarea className={"form-control"} value={this.state.changeSubOrderNote} onChange={(e) => this.handleSubOrder(e, "changeSubOrderNote")} />
                  <div className={"validation-alert"}>
                    {this.validatorOrderStatus.message('Note', this.state.changeSubOrderNote, 'required', 'text-danger')}
                  </div>
                </div>
                <div className="col-md-12 form-group text-center">
                  <button type="submit"
                    className="mt-lg-4 mr-1 btn bg-newcol expand-right">Submit
                  </button>
                  <button type="button" disabled="" onClick={this.changeStatus}
                    className="mt-lg-4 mr-1 btn btn-dark expand-right">Cancel
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        {this.state.editStatus ? <div>
          {(!this.props.match.params.i || !this.props.match.params.j) &&
            <div className={"row col-11 col-sm-10 col-md-7 mt-3 ml-auto mr-auto editor-product-content"}>
              <div className={"col-sm-6 mb-2"}>
                <b>Name:</b> {templateJson && templateJson.name}
              </div>
              <div className={"col-sm-6 mb-2"}>
                <b>Sub Order Id:</b> {orderDetail && orderDetail.sub_order_id}
              </div>
              {/* {console.log(layoutJson)} */}
              {layoutJson && layoutJson.map((page, i) => {
                let templateWidth, templateHeight ,paddingTop
                if (page && page.orientation === "landscape") {
                  templateWidth = templateFrameHeight
                  templateHeight = templateFrameWidth
                }
                else if(this.state.slug === "wall_mount_landscape") {
              
                  templateHeight = templateFrameHeight * 2
                  templateWidth=templateFrameWidth
                  paddingTop = 100 * (templateHeight / templateWidth);
                }
                else {
                  templateWidth = templateFrameWidth
                  templateHeight = templateFrameHeight
                }
                console.log(page)
                let bgImage
                if (page && (page.id || page.backgroundId) ) {
                {page.backgroundId ?
                  bgImage = "https://s3.ap-south-1.amazonaws.com/zoomin-new/live-product/" + slug + "/" + version + "/assets_client/pages/" + page.backgroundId  + ".png"
                  :
                  bgImage = "https://s3.ap-south-1.amazonaws.com/zoomin-new/live-product/" + slug + "/" + version + "/assets_client/pages/" + page.id  + ".png"
                }
      
                  return (<div className={`${layoutJson.length > 1 ? "col-6" : "col-12 col-sm-7 ml-auto mr-auto"} mb-2 mt-2`}>
                    <div className={"editor-content"}>
                    <img className={"editor-background-image"} src={bgImage} />
                      {page.placeholders &&
                        page.placeholders.map((placeholders, j) => {
                          let pageBook = pageJson[i].placeholders[j]
                          let percentageWidth
                          if (i === 0 && page.placeholders[j].displayTruncatingOffset) {
                            percentageWidth = (((placeholders.frame[2] - page.placeholders[j].displayTruncatingOffset[2]) / templateWidth) * 100) + '%';
                          } else {
                            percentageWidth = ((placeholders.frame[2] / templateWidth) * 100) + '%';
                          }

                          let percentageHeight
                          if (i === 0 && page.placeholders[j].displayTruncatingOffset) {
                            percentageHeight = (((placeholders.frame[3] - page.placeholders[j].displayTruncatingOffset[3]) / templateHeight) * 100) + '%';
                          } else {
                            percentageHeight = ((placeholders.frame[3] / templateHeight) * 100) + '%';
                          }
                          let marginLeft
                          if (i === 0 && page.displayTruncatingOffset) {
                            marginLeft = (((placeholders.frame[0] - page.displayTruncatingOffset[0]) / templateWidth) * 100) + '%';
                          }
                          else if (i === 0 && page.placeholders[j].displayTruncatingOffset) {
                            marginLeft = (((placeholders.frame[0] - page.placeholders[j].displayTruncatingOffset[0]) / templateWidth) * 100) + '%';
                          }
                          else {
                            marginLeft = ((placeholders.frame[0] / templateWidth) * 100) + '%';
                          }
                          let marginTop
                          if (i === 0 && page.placeholders[j].displayTruncatingOffset) {
                            marginTop = (((placeholders.frame[1] - page.placeholders[j].displayTruncatingOffset[1]) / templateHeight) * 100) + '%';
                          } else {
                            marginTop = ((placeholders.frame[1] / templateHeight) * 100) + '%';
                          }
                             
                            
                         console.log(percentageWidth,marginLeft)
                          let deg, imageWidth, imageHeight, cropHeight, cropWidth, x, y, image;
                          if (placeholders.type === "photo") {
                            image = pageBook.photourl;
                            deg = pageBook.degree;
                            x = pageBook.frame[0];
                            y = pageBook.frame[1];
                            cropWidth = pageBook.frame[2];
                            cropHeight = pageBook.frame[3];
                            return (
                                
                              <img
                                onClick={() => this.redirectImageEditor(i, j, placeholders.frame[2], placeholders.frame[3], image, templateHeight, templateWidth)}
                                className={`image-placeholder ${this.state.order[i].placeholders[j].contentMode === "aspectFit" ? "aspectImage" : ""} `}
                                i={j}
                                style={{ width: percentageWidth, height: percentageHeight, left: marginLeft, top: marginTop }}
                                src={cropWidth === 0 && cropHeight === 0 ? image :
                                  //"https://process.filestackapi.com/AS6IcbWmTSZK2jYLeo85nz/rotate=deg:exif/rotate=deg:"+deg+"/crop=dim:["+x+","+y+","+cropWidth+","+cropHeight+"]/resize=width:600/"+image}
                                  "https://d1boicrasyl7jn.cloudfront.net/zoominuploader_imageprocess_pdfer?key=" + image + "&rotate=" + deg + "&crop=" + Math.floor(x) + "," + Math.floor(y) + "," + Math.floor(cropWidth) + "," + Math.floor(cropHeight) + "&width=600"}
                              />
                              
                                  
                             )
                         
              

                          }

                          else if (placeholders.type === "text") {
                            let fontSize = (widthPage / (templateWidth * 37.7952755906)) * ((placeholders.fontSize > 60 ? placeholders.fontSize / 1.5 : placeholders.fontSize))
                            let minFontSize = placeholders.minFontSize ? ((widthPage / (templateWidth * 37.7952755906)) * (placeholders.minFontSize)) : fontSize / 2
                            return (
                              <Textfit
                                mode={placeholders.numberOfLines === 1 ? "single" : "multiple"}
                                className="text-placeholder" max={fontSize} min={minFontSize < 1 ? 1 : minFontSize} style={{
                                  width: percentageWidth,
                                  height: percentageHeight,
                                  whiteSpace: `${placeholders.numberOfLines === 1 ? "" : ""}`,
                                  wordWrap: `${placeholders.numberOfLines === 1 ? "" : "break-word"}`,
                                  top: `calc(${marginTop})`,
                                  left: marginLeft,
                                  fontFamily: `${placeholders.fontName}`,
                                  color: placeholders.color,
                                  // paddingTop:widthPage/(template.size[0] * 37.7952755906)  >= 1 ? `calc(${fontSize -2}px)` : "",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                  lineHeight: "normal",
                                  textTransform: placeholders.textCapitalization === "all" ? "uppercase" : "",
                                  textAlign: placeholders.textalign,
                                }}>{pageBook.text ? pageBook.text : "Text here"}</Textfit>
                            )
                          }
                          
                          return ""
                        })
                      }

                      {/* {console.log(pageJson)}  */}
                      {aspectList.includes(this.state.slug) &&
                        <div
                          className={"toggle-fit-fill"}
                          onClick={() => this.toggleAspectFitFill(i)}
                        >
                          <img src={logo} />
                        </div>
                      }
                            {/* {page.placeholders && page.placeholders.map((placeholders, j)=>{
                              
                               let pageBook = pageJson[i].placeholders[j]
                               return(
                              <div
                              className={"icon-fit-fill"}
                             onClick={() => this.redirectImageEditor(i, j, placeholders.frame[2], placeholders.frame[3], pageBook.photourl, templateHeight, templateWidth)}
                            >
                              
                              <img src={check} />
                            </div>
                               )
                            }
               ) } */}
                     
                       
                      


                      {
                        pageJson[i].stickers && pageJson[i].stickers.length > 0 ?
                          pageJson[i].stickers && <div className={`sticker`} style={{
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                          }}>

                            {
                              pageJson[i].stickers.map((arr, is) => {
                                //console.log(arr);
                                let image = Helpers.awsStickerUrl("sticker", "1.1.0", arr.id)
                                let stickerPercentageWidth = (arr.frame[2] / templateWidth) * 100 + "%";
                                let stickerPercentageHeight = (arr.frame[3] / templateHeight) * 100 + "%";
                                let stickerPercentageTop = ((arr.frame[1] - (arr.frame[2] / 2)) / templateHeight) * 100 + "%";
                                let stickerPercentageLeft = ((arr.frame[0] - (arr.frame[3] / 2)) / templateWidth) * 100 + "%";
                                return (
                                  // <div className={`stickerContent`} style={{zIndex: i+1, width: stickerPercentageWidth, height: stickerPercentageHeight, top: stickerPercentageTop, left: stickerPercentageLeft, transform: "rotate("+arr.degree+"deg)"}}>
                                  //   <div className={`stickerImage`}>
                                  //     <img className={"image-inside"} src={image}/>

                                  //   </div>
                                  // </div>
                                  <div className={`stickerContent_${i}_${is}`} style={{ zIndex: is + 1, width: stickerPercentageWidth, height: stickerPercentageHeight, top: stickerPercentageTop, left: stickerPercentageLeft, transform: "rotate(" + arr.degree + "deg)" ,position:"absolute"}}>
                                    <div className={`stickerImage ${activePosition === i ? "active" : ""}`}>
                                      <img className={`image-inside_${i}_${is}`}
                                        src={image}
                                        
                                        onTouchStart={(e) => this.startTouch(e, is, i)}
                                        onTouchEnd={() => { this.endResizeTouch(is, i) }}
                                        onMouseDown={(e) => { this.startMouse(e, is, i) }} />
                                      <div className={`sticker-controller ${activePosition === i ? "active" : ""}`}>
                                        <span className={`rotate-controller_${i}_${is}`}
                                          style={{right: "-5px",
                                            bottom: "-5px",
                                            cursor: "s-resize",
                                            backgroundImage: "url(https://ik.imagekit.io/rnpxntwvs/webresources/images/rotate_resize.png)",
                                            backgroundSize: "14px",
                                            backgroundPosition: "2 2",
                                            transform: "rotate(125deg)",
                                            backgroundRepeat: "no-repeat"}}
                                          onTouchStart={(e) => this.rotateTouch(e, is, i)}
                                          onTouchEnd={() => { this.endRotateTouch(is, i) }}
                                          onMouseDown={(e) => this.rotateImage(e, is, i)} />
                                        {/* <span className={"close-controller"} onClick={() => this.deleteImage(is)} /> */}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                          :

                          <div className={`stickerContent`}>
                            <div className={`stickerImage`}>
                              <img className={`image-inside_${i}_${0}`}

                              />
                              <div className={`sticker-controller`}>
                                <span className={"rotate-controller"} />
                                <span className={"close-controller"} />
                              </div>
                            </div>
                          </div>
                      }
                       </div>
                       <div className='d-flex'>
                       {this.state.pageJson[i].placeholders.length>0 && 
                    this.state.pageJson[i].placeholders.map((placeholders,j)=>{
                  // console.log(placeholders);
                  if(placeholders.type==="photo")
                return(
                  
                  <div className='p-2' onClick={() => this.redirectImageEditor(i, j, placeholders.frame[2], placeholders.frame[3], placeholders.photourl ? placeholders.photourl:"", templateHeight, templateWidth)}>
                 <i className='font-2xl fa fa-edit edit-icon'></i> 
                 </div>
                )
                       })
              }
                </div>


                       {this.state.pageJson[i].placeholders.length>0 && 
              (this.state.pageJson[i].placeholders[0].zoomScale != 1 ||
                  this.state.pageJson[i].placeholders[0].frame[0] != 0 ||
                  this.state.pageJson[i].placeholders[0].frame[1] != 0 ||
                  this.state.pageJson[i].placeholders[0].frame[2] != 0 ||
                  this.state.pageJson[i].placeholders[0].frame[3] != 0) &&


                  <p className='mt-3 text-center'>This image has  already edited.</p>
                }
                  </div>)
                }
              })}
              <div className={"editor-bottom-bar"}>
                <div className={"col-11 col-sm-10 col-md-7 ml-auto mr-auto row justify-content-between"}>
                  <div className={"col-auto row"}>
                    <div className={"col-auto"}>
                      <button className="btn btn-block btn-danger" disabled={orderJsonData !== JSON.stringify(pageJson)} onClick={() => this.generatePdf()}>Generate PDF</button>
                    </div>
                    <div className={"col-auto"}>
                      <button className="btn btn-block btn-danger" disabled={orderJsonData !== JSON.stringify(pageJson)} onClick={() => this.changeStatus()}>Reprocess</button>
                    </div>
                  </div>
                  <div className={"col-auto"}>

                  </div>
                  <div className={"col-auto"}>
                    <button className="btn btn-block btn-danger" disabled={orderJsonData === JSON.stringify(pageJson)} onClick={() => this.clickSavePageJson()}>Save Order</button>
                  </div>
                </div>
              </div>
            </div>
          }


          {
            (this.props.match.params.i && this.props.match.params.j) &&

            <>
              <div className={"row col-11 col-sm-10 col-md-7 ml-auto mr-auto mt-5"}>

               {console.log(width)}
                <AvatarEditor
                  ref={this.setEditorRef}
                  //image={`https://cdn.filestackcontent.com/AS6IcbWmTSZK2jYLeo85nz/rotate=deg:exif/${this.state.image}`}
                  image={`https://d1boicrasyl7jn.cloudfront.net/zoominuploader_imageprocess_pdfer?key=${this.props.location.state.image}`}
                  width={width}
                  height={height}
                  border={0}
                  className={"image-editor"}
                  position={position}
                  onPositionChange={() => this.handlePositionChange()}
                  onImageReady={this.onImageReady}
                  scale={scale}
                  rotate={rotate}
                />

              

              </div>

              <div>
                {/* {console.log(this.state.pageJson[this.props.match.params.i].placeholders[this.props.match.params.j].frame)} */}

                {(this.state.pageJson[this.props.match.params.i].placeholders[this.props.match.params.j].zoomScale != 1 ||
                  this.state.pageJson[this.props.match.params.i].placeholders[this.props.match.params.j].frame[0] != 0 ||
                  this.state.pageJson[this.props.match.params.i].placeholders[this.props.match.params.j].frame[1] != 0 ||
                  this.state.pageJson[this.props.match.params.i].placeholders[this.props.match.params.j].frame[2] != 0 ||
                  this.state.pageJson[this.props.match.params.i].placeholders[this.props.match.params.j].frame[3] != 0) &&


                  <p className='mt-3 text-center'>This image has  already edited.</p>
                }

                <div className={"image-editing-controller"}>
                  <span onClick={() => this.rotateLeft()}><img className="icon" src="https://d29hh3cevg0gwl.cloudfront.net/images/rotate-left.png" alt={"rotate-left"} /></span>
                  <span onClick={() => this.rotateRight()}><img className="icon" src="https://d29hh3cevg0gwl.cloudfront.net/images/right.png" alt={"rotate-right"} /></span>
                </div>
              </div>
              <div className={"input-range"} style={{ marginTop: '40px', marginLeft: 'auto', marginRight: 'auto' }}>
                <InputRange
                  step={0.1}
                  maxValue={6}
                  minValue={1}
                  value={Math.round(value * 10) / 10}
                  onChange={value => {
                    this.setState({
                      value,
                      scale: value
                    })
                  }} />
              </div>
              <div className={"editor-bottom-bar"}>
                <div className={"col-11 col-sm-10 col-md-7 ml-auto mr-auto row"}>
                  <div className={"col-auto ml-auto"}>
                    <button className="btn btn-block btn-danger" onClick={() => this.clickSave()}>Save Image</button>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
          :
          <p className={"text-center mt-5"}><b>You can't edit this order</b></p>
        }
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(OrdersEditor);