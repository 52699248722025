// let category = { name: 'Category', url: '/categorylist', icon: 'icon-user' };

import Helpers from "./service/Helpers";
import JWT from "jsonwebtoken";

let dashboard = { name: 'Dashboard', url: '/dashboard', icon: 'icon-home' };
let mdashboard = { name: 'MDashboard', url: '/mdashboard', icon: 'icon-home' };
let admin = { name: 'Admin', url: '/admins', icon: 'icon-shield' };
let users = { name: 'Users', url: '/users', icon: 'icon-people' };
let products = { name: 'Products', url: '/products', icon: 'icon-briefcase' };
let newCategory = { name: 'New Category', url: '/new-category', icon: 'icon-briefcase' };

let orders = { name: 'Orders', url: '/orders', icon: 'icon-basket-loaded' };
// let referral = { name: 'Referral Code', url: '/referral-code', icon: 'icon-star' };
let coupons = { name: 'Coupons', url: '/coupons', icon: 'icon-puzzle' };
let shipping = { name: 'Shipping', url: '/shipping', icon: 'icon-speedometer' };
let reports = { name: 'Reports', url: '/reports', icon: 'icon-note' };
let financials = { name: 'Financials', url: '/financials', icon: 'icon-list' };
let promobanners = { name: 'Promo & Banners', url: '/promobanners', icon: 'icon-map' };
let partner = { name: 'Partners', url: '/partners', icon: 'icon-trophy' };
let campaign = { name: 'Campaigns', url: '/campaigns', icon: 'icon-globe' };
let configurtaion = { name: 'Configuration', url: '/configuration', icon: 'icon-settings' };
let courier = { name: 'Couriers', url: '/couriers', icon: 'icon-envelope-letter'};
let postalcode = { name: 'Postal Codes', url: '/postal-code', icon: 'icon-location-pin'};
let offers = { name: 'Offers', url: '/offers', icon: 'icon-tag'};
let mobilelanding = {name:'Mobile Landing', url:'/mobile-landing', icon:'icon-tag'};
let category =  {name:'Category', url:'/category', icon:'icon-tag'};
let blog = {name:'Blog', url:'/blog', icon:'icon-tag'}
let imagedownload={name:'Image Download', url:'/image-download', icon:'icon-tag'}
let theme={name:'Theme Finder',url:'/theme-artist',icon:'icon-tag'}
let footer = { name: 'Footer', url: '/footer', icon: 'icon-briefcase' };
let seoMeta = { name: 'SEO Meta', url: '/seo-meta', icon: 'icon-briefcase' };
let manifest = { name: 'Manifest', url: '/manifest', icon: 'icon-briefcase' };
let cronConfig = { name: 'Cron Config', url: '/cron-configuration', icon: 'icon-briefcase' };
let review = { name: 'Review', url: '/review', icon: 'icon-briefcase' };
let vendor = { name: 'Vendor', url: '/vendor', icon: 'icon-briefcase' };

let adminRole;
let token = Helpers.getLocalStorageData('adminToken');
if(token !== null){
  try{
    let decode = JWT.decode(token);
    if (decode !== null ) {
      adminRole = parseInt(decode.userData.AdminRoleId)
    } else {
      adminRole = 0
    }
  } catch(err) {
    adminRole = 0
  }

}

let menu;

if(adminRole === 1) {
  menu = [
    dashboard,
    mdashboard,
    admin,
    users,
    products,
    orders,
    // referral,
    coupons,
    offers,
    shipping,
    reports,
    financials,
    promobanners,
    partner,
    campaign,
    postalcode,
    courier,
    configurtaion,
    mobilelanding,
    category,
    blog,
    imagedownload,
    theme,
    newCategory,
    footer,
    seoMeta,
    manifest,
    cronConfig,
    review,
    vendor
  ]
} else if(adminRole === 2) {
  menu = [
    dashboard,
    users,
    orders,
    // referral,
    coupons,
    offers,
    shipping,
    reports,
    promobanners,
    partner,
    campaign,
    blog,
    mobilelanding,
    category,
    footer,
    seoMeta,
    manifest,
    newCategory,
    cronConfig,
    review
  ]
} else if(adminRole === 3) {
  menu = [
    dashboard,
    users,
    orders,
    // referral,
    coupons,
    offers,
    reports,
    review
  ]
} else if(adminRole === 4) {
  menu = [
    dashboard,
    users,
    orders,
    shipping,
    reports,
    postalcode,
    imagedownload
  ]
} else if(adminRole === 5) {
  menu = [shipping]
} else {
  menu = [dashboard]
}
export default {
  items: menu
}




